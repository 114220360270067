.container-switch-radar {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -2rem;
    right: .5rem;

    & label {
        margin: 0;
        margin-right: 0.5rem;
        cursor: pointer;
    }
}


#not-found {
    width: 70vw;
    background-color: #0A223D;
    height: 200px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-top: 200px;
    margin: 1em auto 1em auto;

    h2 {
        font-size: 30px;
    }
}
.navbar {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: static;
    background-color: #0A223D;
    color: #fff;
}

.navbar-user-info {
    display: flex;
    align-items: center;
}

.navbar-user-icon {
    width: 50px;
    height: 50px;
    background-color: rgb(165, 165, 165);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;

    p {
        margin: 0;
        font-size: 25px;
    }
}

#work-in-progress {
    width: 70vw;
    background-color: #0A223D;
    height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-top: 1em;
    margin: 1em auto 1em auto;

    h2 {
        font-size: 20px;
    }
}





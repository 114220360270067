body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ximdex-card {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, .125);
  padding: 0.7rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

img[src='/static/images/mhe-logo.png'] {
  height: 60px;
  border-radius: 4px;
}
.note-mark {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 11px;
    position: absolute;
    bottom: -8px;
    left: -5;
    width: max-content;
}

.circle {
    width: 90%;
    height: 90%;
}